<template>
  <div class="p-5">
      <div class="mb-5">
          <h1 class="text-4xl mb-5">
              TERMS AND CONDITIONS
          </h1>
          <p>
              Welcome to {{ company}} and thank you for visiting our website! When you use our services, you're agreeing to our terms. So please take a few minutes to read over the below mentioned Terms and Conditions before using our website.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 1 - YOUR USE OF THE SERVICES
          </h2>
          <p>{{ company}} grants you a personal, non-transferable, non-exclusive, revocable, limited license to use and access the Services solely as permitted by these Terms. We reserve all rights not expressly granted to you by these Terms. We reserve the right to modify, suspend, or discontinue the Services (in whole or in part) at any time, with or without notice to you. Any future release, update, or other addition to functionality of the Services will be subject to these Terms, which may be updated from time to time. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Services or any part thereof.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 2 - PERSONAL RESPONSIBILITY
          </h2>
          <p class="mb-3">
              {{ company}} provides consumers with a fast, easy, and convenient way to request booking for their car window tinting services. Prices furnished are accurate at the moment gathered and are subject to change at any time. {{ company}} may, in its discretion, include in its service.  
          </p>
          <p class="mb-3">
              You aim to accurately represent the information provided to us on or through our website. You acknowledge that you are participating voluntarily in using our website and that you are solely and personally responsible for your choices, actions and results, now and in the future. You accept full responsibility for the consequences of your use, or non-use, of any information provided on or through this website, and you agree to use your own judgment and due diligence before implementing any idea, suggestion or recommendation from our website to your life, family or business.
          </p>
          <p class="mb-3">{{ company}}’s services are only administrative. Any inquiry you submit is NOT contract for services. Rather, it is an inquiry to be matched with us to receive conditional service offers. You may have to complete a contract with us before we provide you with tinting services.
          </p>
          <p class="mb-3">
              Vehicles must be delivered to our location clean and free of grease, dirt, oil, hair, debris, and free from existing window film material. {{ company}} is not responsible for the condition of the surface the car is brought to us in. Any existing scratches, cracks or other damage will be visible through the film, and such surface damage or imperfections may damage the film, cause the film to fail prematurely or the window film material may not adhere to the surface at all. We are not responsible for broken windows if there is prior damage prior to installation.
          </p>
          <p class="mb-3 font-bold">
              WE AR NOT RESPONSIBLE FOR THE CONDITION OF THE GLASS/VEHICLE PRIOR TO THE WINDOW FILM BEING INSTALLED OR WHAT IS UNDERNEATH THE WINDOW FILM ONCE IT IS INSTALLED.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 3 - VEHICLE INSPECTION
          </h2>
          <p>
              {{ company}} will complete a pre-inspection the day the car is delivered and alert the customer of any concerns prior to work being performed. We will also perform a post-inspection once the vehicle is completed prior to the release of the vehicle.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 4 - VEHICLE MAINTAINENCE
          </h2>
          <p class="mb-3">Window film is a pliable material that is made to be installed on glass. We make every effort to make it look as clean and seamless as possible, however, there are certain surfaces that window film will not adhere to such as plastic, rubber, dot matrix, etc. and customers are not advised to install on such areas. If the customer requests that these areas be installed, we are not responsible for the material not sticking or adhering to those surfaces and we will not warranty such items.</p>
          <p class="mb-3">{{ company}} is not responsible for the vehicle's maintenance, condition or any mechanical malfunctions that may occur when the vehicle is in {{ company}} possession. If a vehicle needs to be repaired or taken to a repair shop / dealership for any reason, including but not limited to: maintenance, recalls, electronic malfunctions, parts, etc. S3Tint is not responsible for any of the costs and is the owner's sole responsibility to pay for those costs. {{ company}} has permission to operate customers vehicle for services requested in work order. Operation of vehicles may include delivery, testing, and or outsourcing at difference locations.</p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 5 - PAYMENT
          </h2>
          <p>
              The customer agrees to make full payment prior to the release of the products or vehicle. The vehicle will not be released until full payment has been processed accordingly. This is not an expectation contract and we are not responsible for buyers’ remorse. By accepting the product or vehicle you are accepting the job as complete and that the performance is satisfactory. You are responsible for inspecting the product and or vehicle upon receipt.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 6 – REFUNDS
          </h2>
          <p>
              If an issue arises the customer must submit their complaint in writing and give {{ company}} the opportunity to see the issue and try and resolve the issue prior to pursuing any refund or replacement. Since all window film installations are customized to the customers specifications customers are required to inform {{ company}} within 15 days of the service to claim refund, removal or reinstallation.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 7- PROHIBITED CONDUCT
          </h2>
          <p class="mb-3">Users must not use the {{ company}} website for illegal, unlawful or prohibited purposes. This includes sending or posting junk e-mail or spam, publishing misleading, defamatory, indecent, obscene or advertising material, or send viruses and worms.</p>
          <p class="mb-3">Users must not impersonate any other person or entity or to use a false name or a name that they have no authority to use.</p>
          <p class="mb-3">Users must not post material to the {{ company}} website in which the copyright or intellectual property is or may be the property of another person or body.</p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 8 - MODIFICATIONS TO SERVICES
          </h2>
          <p>
              {{ company}} shall at its discretion been titled to modify the terms and conditions of the terms of use and qualifications and conditions mentioned herein. The User at all times shall be bound by the rules/regulations/qualifications/conditions (as may be altered) at all times and no person shall be entitled to dispute/challenge any such modifications by {{ company}} from time to time.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 9 - INDEMNIFICATION
          </h2>
          <p>
              You agree to defend, indemnify and holdus and our members, owners, directors, officers, employees and agents harmlessfrom any and all claims, liabilities, costs and expenses, including reasonableattorneys' fees, arising in any way from any content or other material youplace on the Platform or submit to us, or your breach or violation of the lawor of these Terms and Conditions. We reserve the right, at our own expense, toassume the exclusive defense and control of any matter otherwise subject toindemnification by you, and in such case, you agree to cooperate with ourdefense of such claim.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 10 - TERMINATION
          </h2>
          <p>
              We may terminate, change, suspend or discontinue any aspect of the services at any time. We may restrict, suspend or terminate your access to the services if we believe you are in breach of these Terms and Conditions or applicable law, you are a repeat infringer of intellectual property rights, or for any other reason without notice or liability.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 11 - COMMUNICATION
          </h2>
          <p>
              If you provide us your email address, you agree and consent to receive email messages from us. These emails may be transactional or relationship communications relating to the products or services we offer, such as administrative notices and service announcements or changes.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 12 - WAIVERS
          </h2>
          <p>
              Our failure to act with respect to a breach of these Terms and Conditions by you or others does not waive our rightto act with respect to that breach or subsequent similar or other breaches.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 13 - FORCE MAJEURE
          </h2>
          <p>
              Neither party here to shall be responsible for delays or failures in performance resulting from acts beyond its reasonable control and without its fault or negligence. Such excusable delays or failures may be caused by, among other things, strikes, lock-out, riots, rebellions,accidental explosions, floods, storms, acts of God and similar occurrences.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 14 - DISCLAIMER OF WARRANTIES
          </h2>
          <p class="mb-3 uppercase">
              (a) THE SITE, INCLUDING BUT NOT LIMITED TO ALL SERVICES, PRODUCTS, CONTENT, FUNCTIONS AND MATERIALS CONTAINED OR AVAILABLE ON THE SITE, IS PROVIDED "AS IS," "AS AVAILABLE", WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOTLIMITED TO ANY WARRANTY REGARDING UPTIME OR UNINTERRUPTED ACCESS, AVAILABILITY, ACCURACY, OR USEFULNESS, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE HEREBY DISCLAIM ANY AND ALL SUCH WARRANTIES, EXPRESSED AND IMPLIED. WE ALSO ASSUME NO RESPONSIBILITY, AND WILL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT, MOBILE DEVICE, OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO OR USE OF THE SITE OR YOUR DOWNLOADING OF ANY MATERIALS FROM THE SITE. IF YOU ARE DISSATISFIED WITH THE SITE, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>
          <p class="mb-3 uppercase">
              (b) WE DO NOT: (I) GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY THIRD-PARTY CONTENT ON THE SITE OR ANY VERIFICATION SERVICES DONE ON OUR INSTRUCTORS OR INSTRUCTORS, OR (II) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY INSTRUCTOR OR INSTRUCTOR OR ANY PARTY THAT APPEARS ON THE SITE. UNDER NO CIRCUMSTANCES WILL WE BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE RESULTING FROM YOUR RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON OR AVAILABLE FROM THE SITE.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 15 - LIABILITY DISCLAIMER
          </h2>
          <p>
              IN NO EVENT, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, WILL WE OR ANY OF OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR CONTENT OR SERVICE PROVIDERS (COLLECTIVELY, THE "PROTECTED ENTITIES") BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED TO, THE USE OF, OR THE INABILITY TO USE, THE SITE OR THE CONTENT, MATERIALS, PRODUCTS, SERVICES, AND FUNCTIONS RELATED TO THE SITE, YOUR PROVISION OF INFORMATION VIA THE SITE, LOST BUSINESS OR LOST SALES, EVEN IF SUCH PROTECTED ENTITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS TO THE EXTENT REQUIRED BY APPLICABLE LAW.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 16 - GOVERNING LAW AND JURISDICTION
          </h2>
          <p>
              These Terms and Conditions will begoverned by and construed in accordance with {{nation}} law, and the courts of {{state}} will have non-exclusive jurisdiction to adjudicate any dispute arisingunder or in relation to these terms of sale. Any claim, action, lawsuit orproceeding arising out of or related to this Platform and the services andproducts provided, shall be instituted exclusively in the courts of {{state}} andthe user hereof irrevocably submits to the exclusive jurisdiction of suchcourts in any claim, action, lawsuit or proceeding, and waives any objectionbased on improper venue.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 17 - COPYRIGHT
          </h2>
          <p>
              Copyright © 2021 {{ company}}. All rights reserved. All materials presented on this Platform are copyrighted and owned by us, or other individuals or entities as designated. Any republication, retransmission, reproduction, downloading, storing or distribution of all orpart of any materials found on this Platform is expressly prohibited.
          </p>
      </div>
      <div class="mb-5">
          <h2 class="text-3xl mb-5">
              SECTION 18 - ACCEPTING THIS TERMS OF USE
          </h2>
          <p>
              You hereby accept the fact that you have read, understood and are willing to abide by the terms and conditions laid down in this agreement. You further agree that the terms and conditions set out under this agreement are fair, reasonable and just given the matters set out under this agreement and you waive any and all rights to have any claims against us on grounds set out above.
          </p>
      </div>
  </div>
</template>

<script>
import configSetup from '@/setup.js'

export default {
      created() {
    const {company} = configSetup()
    document.title = `${company} | Terms & Conditions`
},
    setup() {
        const {company, state, city, nation} = configSetup()

        return {company, state, city, nation}
    }

}
</script>